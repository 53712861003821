import React, {Component, useState, useEffect, Fragment} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {Edit} from '@styled-icons/material/Edit';
import {AddCircle} from '@styled-icons/material/AddCircle';
import {Close} from '@styled-icons/material/Close';
import Block from '../Widgets/Block';

const appConfig = require('../data.json');

function getFileName(path) {
  return path ? path.slice(path.lastIndexOf('/') + 1) : '無法取得檔名';
}

function Dialog({show, onClose, file, onEdit}) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (file) {
      setName(file.name);
    }
  }, [file]);

  return (
    <Ant.Modal
      visible={show}
      width={600}
      onCancel={onClose}
      footer={[
        <Ant.Spin spinning={loading}>
          <Ant.Space>
            <Ant.Button onClick={onClose} key="cancel">
              取消
            </Ant.Button>
            {onEdit && (
              <Ant.Upload
                key="upload"
                beforeUpload={(_file) => {
                  return false; //not upload immediately
                }}
                fileList={[]}
                onChange={async (info) => {
                  try {
                    setLoading(true);
                    await onEdit({
                      ...file,
                      file: info.file,
                      id: file.id,
                    });
                    onClose();
                  } catch (error) {}
                  setLoading(false);
                }}>
                <Ant.Button>重選檔案 </Ant.Button>
              </Ant.Upload>
            )}
            <Ant.Button
              type="primary"
              key="confirm"
              onClick={async () => {
                setLoading(true);
                let _file = {...file, name};
                delete _file.file;
                try {
                  await onEdit(_file);
                  onClose();
                } catch (error) {}
                setLoading(false);
              }}>
              更新
            </Ant.Button>
          </Ant.Space>
        </Ant.Spin>,
      ]}>
      {file ? (
        <Block>
          <div>
            <div className="row">
              <h4 style={{marginRight: 10}}>檔案</h4>
              <div>{getFileName(file.file)}</div>
            </div>
            <div className="row">
              <h4 style={{marginRight: 10}}>名稱</h4>
              <Ant.Input
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </Block>
      ) : null}
    </Ant.Modal>
  );
}

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      selectedFile: null,
    };
  }

  render() {
    let {files, name, onDelete, onCreate, onEdit, css = ''} = this.props;
    let {showDialog, selectedFile} = this.state;

    return (
      <Wrapper css={css}>
        <div className="files-container">
          {files.map((file, idx) => {
            return (
              <div key={idx} className="file-container">
                <a
                  href={`${file.file}`}
                  target="_blank"
                  style={{marginRight: 10}}>
                  {getFileName(file.file)}
                </a>
                {onEdit && (
                  <div
                    className="icon"
                    onClick={() =>
                      this.setState({showDialog: true, selectedFile: file})
                    }>
                    <Edit size={24} color="#999" />
                  </div>
                )}
                {onDelete && (
                  <div className="icon" onClick={() => onDelete(file)}>
                    <Close size={24} color="#999" />
                  </div>
                )}
              </div>
            );
          })}
          {onCreate && (
            <Ant.Upload
              className="file-uploader"
              beforeUpload={(_file) => false} //not upload immediately
              fileList={[]}
              onChange={async (info) => {
                let resp = await onCreate({file: info.file, name});
                console.log(resp);
              }}>
              <div className="icon">
                <AddCircle size={24} color="#999" />
              </div>
              新增
            </Ant.Upload>
          )}
        </div>
        <Dialog
          show={showDialog}
          onClose={() => this.setState({showDialog: false, selectedFile: null})}
          file={selectedFile}
          onEdit={onEdit}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > .files-container {
    display: flex;
    flex-direction: column;

    & > .file-container {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    & .icon {
      height: 40px;
      width: 40px;
      border: solid 1px #999;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  & .file-uploader .ant-upload {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  & button {
  }

  ${(props) => props.css}
`;

export default FileUploader;
